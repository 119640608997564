import React, {useState, useEffect } from 'react';
import { PageProps, Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import BackgroundImageToUse from "../components/backgroundImage"
import { useTransition, animated, config } from 'react-spring'


type Data = {
  site: {
    siteMetadata: {
      title: string
    }
  }
  allMdx: {
    edges: {
      node: {
        excerpt: string
        frontmatter: {
          title: string
          date: string
          description: string
        }
        fields: {
          slug: string
        }
      }
    }[]
  }
}

const BlogIndex = ({ data } : PageProps<Data>) => {


const BiomassSuagrName = <b>BioMass Sugar <sup>®</sup></b> 


const pages = [
  ({ style }) => 
    <animated.div className="absolute w-100 pointer willchange vh-50 cover bg-center" style={{ ...style, backgroundImage: `url(https://images.unsplash.com/photo-1488928741225-2aaf732c96cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80)` }}>
      <div className="w-100 mw7 vh-50 center flex-center white bg-black-10 pv4 ph5 ls05 f5 f4-m lh-1-25">
      <div className="ts tc">Balancing the carbon to nitrogen ratio of the soil by addition of <b>BioMass Sugar</b> <sup>&reg;</sup> promotes the growth of beneficial organism and increases microbial enzymatic activity.</div>
      </div>
    </animated.div>,
   ({ style }) => 
   <animated.div className="absolute w-100 pointer willchange vh-50 cover bg-center" style={{ ...style, backgroundImage: `url(https://images.unsplash.com/photo-1543528176-61b239494933?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1353&q=80)` }}>
     <div className="w-100 mw7 vh-50 center flex-center white bg-black-10 pv4 ph5 ls05 f5 f4-m lh-1-25">
     <div className="ts tc">On application of <b>BioMass Sugar</b> <sup>&reg;</sup>  to the soil, the sugars are available to provide an additional nutritional value to the micro-organisms in the soil.</div>
     </div>
   </animated.div>,
  ({ style }) => 
  <animated.div className="absolute w-100 pointer willchange vh-50 cover bg-center" style={{ ...style, backgroundImage: `url(https://images.unsplash.com/photo-1587096677895-52478b441d9c?ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80)` }}>
    <div className="w-100 mw7 vh-50 center flex-center white bg-black-10 pv4 ph5 ls05 f5 f4-m lh-1-25">
    <div className="ts tc"><b>BioMass Sugar</b> <sup>&reg;</sup>  stimulates the growth of the root system which supports the immunity of the plant against stress factors.</div>
    </div>
  </animated.div>,
   ({ style }) => 
   <animated.div className="absolute w-100 pointer willchange vh-50 cover bg-center" style={{ ...style, backgroundImage: `url(https://images.unsplash.com/reserve/SLErBhmTnePXNTLk33fw_DSCF3308.jpg?ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80)` }}>
     <div className="w-100 mw7 vh-50 center flex-center white bg-black-10 pv4 ph5 ls05 f5 f4-m lh-1-25">
     <div className="ts tc"><b>BioMass Sugar</b> <sup>&reg;</sup>  contains a high percentage of sugarcane based carbohydrates which are rich in carbon, hydrogen and oxygen.</div>
     </div>
   </animated.div>,
]


const Looky = () => {
const [index, set] = useState(0)
const transitions = useTransition(index, p => p, {
  from: { opacity: 0 },
  enter: { opacity: 1 },
  leave: { opacity: 0 },
  config: config.molasses,
})
useEffect(() => {
 setInterval(() => {  
  set(state => (state + 1) % 4) }, 7000);
  return clearInterval();
}, [])
return (
  <div className="relative db cf vh-50">
    <div className="relative db cf vh-50" >
      {transitions.map(({ item, props, key }) => {
        const Page = pages[item]
        return <Page key={key} style={props} />
      })}
    </div>
  </div>
)
}

const slides = [
  { id: 0, url: './biomass_root_no.jpg', caption: `Before `  },
  { id: 1, url: './biomass_root.jpg', caption: `After ` },
]

const Rootsy = () => {
  const [index, set] = useState(0)
  const transitions = useTransition(slides[index], item => item.id, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: config.molasses,
  })
  useEffect(() => {
    setInterval(() => {
      set(state => (state + 1) % 2)}, 4000);
    return clearInterval();
  }, [])
  return transitions.map(({ item, props, key }) => (
    <div key={key} className="cf relative w-100 bg-white ">
    <animated.div  className="absolute w-100  pointer willchangeOpacity  h300 cover bg-center" style={{ ...props, backgroundImage: `url(${item.url})` }} >
      <div className="f4 fw3 pt1 pb3 bg-white w-100">{item.caption} the application of <b>BioMass Sugar <sup>®</sup></b> </div>
    </animated.div>
    </div>
  ))
}




 

  const posts = data.allMdx.edges

  return (
    <Layout >
      <SEO title="All posts" />

      <Looky />


      <div className="w-100 mw8 center ph4 ph5-s tc pv5 ">
        <div className="mb3 green-100 center"> <span className="f2 fw4 lh-1"> BioMass Sugar</span><sup className="f6">®</sup>   </div>
        <div className="f4 fw3 green-100 mb4 lh-1-25"> A unique formulation of sugars, macronutrients and trace elements produced from annually renewable feedstock</div>
        <div className="f5 fw3 gray-100 ls025 lh-1-5"> BioMass Sugar <sup>®</sup> is an emulsifiable formulation of sugarcane extracts and sugarcane hydrolysates containing a range of macronutrients, trace elements and sugars. </div>
      </div>



      <div className="w-100 center ph4 ph5-l pv6-l cf bg-gray-25">
         <div className="w-100 mw9 center  mb4 pv5 cf">

          <div className="fl w-100 w-50-l ph0 ph5-s"> 
          <div className="pt3 pb4 mb4 ">
            <div className="f4 fw3 bb bw2 b--green-100 pb2 lh-1-25">{BiomassSuagrName} is produced from Sugarcane - a renewable feedstock</div>
            <div className="mw6">
              <p> {BiomassSuagrName} is based on renewable feed stock as it is produced annually from sugarcane.</p>
              <p> {BiomassSuagrName} contains a high percentage of sugarcane based carbohydrates which are rich in carbon, hydrogen and oxygen.</p>
              <p> {BiomassSuagrName} stimulates the growth of the root system which supports the immunity of the plant against stress factors.</p>
            </div>
          </div>
          </div>

          <div className="fl w-100 w-50-l ph0 ph3-s cf"> 
            <div className="relative ph4 pt3 bg-white cf h350 br05">
              <Rootsy />
            </div>
          </div>
          
          </div> 
      </div> 



      <div className="w-100 center cf bg-white-00 flex flex-column flex-row-l">
          <div className="w-100 w-50-l ph0 ph3-s h300 h400-s h600-l cover bg-center" style={{ backgroundImage: `url(  https://images.unsplash.com/photo-1518977822534-7049a61ee0c2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80)` }}>
          </div>
          <div className="w-100 w-50-l pa4 pv5-s ph6-s ">
            <div className="mw6 center tl ">
              <div>
                <div className="mw5 f4 fw3 lh-1-25">{BiomassSuagrName} adds nutritional value to soil and plant</div>
                <p className="bt bw2 b--green-100 pt3"> On application of {BiomassSuagrName} to the soil, the sugars are available to provide additional nutritional value to the micro-organisms in the soil.</p> 
                <p> The micro-organisms share a symbiotic relationship with the plant, and the presence of these micro organisms contribute to a healthier soil and growing environment.</p>
                <p> Balancing the carbon to nitrogen ratio of the soil by addition of {BiomassSuagrName} promotes the growth of beneficial organism and increases microbial enzymatic activity.</p>
                <p> Soil micro-flora are allowed to develop and beneficial populations contribute to the synergistic metabolism of nutrients in both plant and microbes.</p>
              </div>
            </div>
          </div>
      </div> 


      <div className="w-100 center cf bg-white-00 flex flex-column flex-column-reverse flex-row-l">
          <div className="w-100 w-50-l pa4 pv5-s ph6-s ">
            <div className="mw6 center tl ">
  
              <div className="mw5 f4 fw3 lh-1-25">{BiomassSuagrName} stimulates soil condition</div>
                <p className="bt bw2 b--green-100 pt3">Multiple applications of {BiomassSuagrName} during the season strengthen the plant and result in an improved root system. This is a significant advantage as it allows the farmer to strengthen and protect his crop throughout the season and extend growing season length.</p>
                <p>Good soil condition is stimulated through multiple applications of {BiomassSuagrName} which supports the plants resistance to disease and good yields.</p>
                <p>{BiomassSuagrName} is ideal for all soil types, especially sandy profiles, as the organic content is continually maintained with repeated use.</p>
              </div>
        
          </div>
          <BackgroundImageToUse className="w-100 w-50-l ph0 ph3-s h300 h400-s h600-l cover bg-center" name="soil" />
      </div> 

    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    } 
    allFile(filter: { extension: { eq: "jpg" } }) {
        edges {
          node {
           name 
           childImageSharp{
                  fluid( maxWidth: 1280, quality: 80 ) {
                    ...GatsbyImageSharpFluid
                    presentationWidth
                  }
                }
          }
        }
      }
    allMdx(sort: { fields: [frontmatter___date], order: DESC })  {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
